
.table-header-row{
    border:1px solid black;
}

.table-header-col{
    border-left:1px solid black;
    border-right:1px solid black;
    font-weight:bolder
}

.table-row{
    border:1px solid black;

}

.table-col{
    border-left:1px solid black;
    border-right:1px solid black;
   
}
.form-group{
    margin-top:1rem;
}

.header{
    margin-bottom:20px;
}

.button-row{
    margin-top:20px;
}





@media only screen and (max-width: 768px) {
    .table-header-row{
        display: none;
    }
    .desktop-only{
        display: none;
    }
    
}

@media only screen and (min-width: 769px) {
    .mobile-only{
        display: none;
    }
    
}